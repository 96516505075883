import React from 'react';
import { CenterCardContainer } from '../styles/styles';
const BigCard = () => {
  return (
    <CenterCardContainer>
      <h1 className="big-card-title">We are starting!</h1>
      {/* <br /> */}
      <div className="big-card-subtitle">
        Feel free to reach out! Also, if you are a Developer or Designer and you want to get involved, write us at: <br />
        🤓 <span>hello@designlobby.app</span>
      </div>
    </CenterCardContainer>
  );
};

export default BigCard;
