import React, { useEffect } from 'react';
import Header from './Header';
import Hero from './Hero';
import Features from './Features';
import BigCard from './BigCard';
import Footer from './Footer';

const Site = () => {
  useEffect(() => {
    let PATTERN = /accessToken/;
    let cat = Object.keys(localStorage).filter(str => PATTERN.test(str));
    if (cat.length > 0) {
      window.location.pathname = '/login';
    }
  }, []);

  useEffect(() => {
    if (window.location.hash.includes('Already+found+an+entry+for+username')) {
      window.location.pathname = '/login';
    }
  }, []);
  return (
    <>
      <Header />
      <Hero />
      <Features background="linear-gradient(180deg, #f7f5f0 0%, #fff 44.16%)" />
      <BigCard />
      <Footer />
    </>
  );
};

export default Site;
