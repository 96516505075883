import React from 'react';
import { Container, HeroContainer, BannerContant, BannerBg } from '../styles/styles';
import Preview from '../static/images/preview.png';
import Color from '../static/images/color.png';
import line from '../static/images/line.svg';
import Highlight from '../static/images/highlight.png';

const Hero = () => {
  return (
    <Container>
      <HeroContainer>
        <BannerContant>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <a style={{ width: '250px' }} href="https://www.producthunt.com/posts/design-lobby?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-design-lobby" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=280050&theme=dark" alt="Design Lobby - Home for Digital Design Assets | Product Hunt" style={{ width: '250px', height: '54px' }} />
            </a>
          </div>
          <div style={{ position: 'relative', paddingTop: '45px' }}>
            <h1 className="banner-title">
              Where creators manage their
              <br /> design assets
            </h1>
            <div className="bubbles" style={{ top: '25px', left: '140px', backgroundColor: '#f5798c' }}></div>
            <div className="bubbles" style={{ top: '15px', right: '280px', backgroundColor: '#FE6E2F' }}></div>
            <div className="bubbles" style={{ top: '125px', left: '-50px', backgroundColor: '#FDB52F' }}></div>
            <div className="bubbles" style={{ top: '180px', right: '0px', backgroundColor: '#E9C193' }}></div>
            <div className="bubbles" style={{ top: '180px', left: '180px', backgroundColor: '#5AB59F' }}></div>
            <div className="line">
              <img src={line} alt="line" />
            </div>
            <p className="banner-subtitle">Design Lobby helps you manage design assets & version control. Built for high-performance design teams and freelancers</p>
            <div>
              <img src={Highlight} style={{ position: 'absolute', width: '50px', top: '274px', left: '460px' }} />
              <a className="request-btn" href="/signup">
                Get Started for Free
              </a>

              {/* <div className="label-style">
                <span>Try Design Lobby Beta for Free - No credit card needed</span>
              </div> */}
            </div>
          </div>
          <BannerBg>
            <img alt="color" className="color" src={Color} />
            <img alt="preview-img" className="preview-img" src={Preview} />
          </BannerBg>
        </BannerContant>
      </HeroContainer>
    </Container>
  );
};

export default Hero;
