import React from 'react';
import { GlobalStyle } from '../styles/global';
import SEO from '../components/seo';
import Site from '../components/site';

const index = () => {
  return (
    <React.Fragment>
      <SEO title="Home" />
      <GlobalStyle />
      <Site />
    </React.Fragment>
  );
};

export default index;
